
import {SheetState} from './sheet_state'
import {STATE, VIDEO_SIZE, DEFAULT_LINE_WIDTH, DEFAULT_RADIUS} from './params';

export class DrawingBoard {
  constructor() {
    this.size = VIDEO_SIZE[STATE.camera.sizeOption];

    var canvas = document.createElement("canvas");
    canvas.id = "drawing-board";
    canvas.width = this.size.width;
    canvas.height = this.size.height;
    canvas.style.position = 'absolute';
    canvas.style.top = STATE.renderOffset.top;
    canvas.style.left = STATE.renderOffset.left;
    canvas.style.zIndex = -1;
    document.body.appendChild(canvas);
    
    this.ctx = canvas.getContext('2d');
    this.ctx.fillStyle = 'Red';
    this.ctx.strokeStyle = 'Black';
    this.ctx.lineWidth = DEFAULT_LINE_WIDTH;
  }

  draw(sheetState) {
    // TODO: From a computational perspective, it would be better to not draw all points / paths each time, but simply keep the ones which are not
    // altered anymore visible.
    this.clearCtx();

    // TODO: Decide whether to use bezier or resampled curve world
    /*for(let i = 0; i < sheetState.strokes.length; i++) {
      this.drawPath(sheetState.strokes[i], false);
    }*/
    for(let i = 0; i < sheetState.bezierStrokes.length; i++) {
      this.drawBezierStroke(sheetState.bezierStrokes[i], false);
    }

    this.drawPath(sheetState.currentStroke, false);

    for(let i = 0; i < sheetState.drawingFingerKeypoints.length; i++) {
      this.drawPoint(sheetState.drawingFingerKeypoints[i]);
    }

    this.drawPoint(
      {"x": 0.5, "y": 0.5}, 
      sheetState.lastClosenessReference / 2,
    );    
  }

  clearCtx() {
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
  }

  scalePoint(point) {
    return {
      "x": point.x * this.size.width,
      "y": point.y * this.size.height,
    }
  }

  scaleArray(arr) {
    return [
      arr[0] * this.size.width,
      arr[1] * this.size.height,
    ];
  }

  drawPath(points, closePath) {
    if(points.length > 0){
      const path = new Path2D();
      let scaledPoint = this.scalePoint(points[0]);
      path.moveTo(scaledPoint.x, scaledPoint.y);
      for (let i = 1; i < points.length; i++) {
        const point = this.scalePoint(points[i]);
        path.lineTo(point.x, point.y);
      }

      if (closePath) {
        path.closePath();
      }
      this.ctx.stroke(path);
    }
  }

  drawBezierStroke(bezierCurves, closePath) {
    if(bezierCurves.length > 0){
      const path = new Path2D();
      let scaledStartPoint = this.scaleArray(bezierCurves[0][0]);
      path.moveTo(scaledStartPoint.x, scaledStartPoint.y);
      for(let i = 0; i < bezierCurves.length; i++) {
        let controlPoint1 = this.scaleArray(bezierCurves[i][1]);
        let controlPoint2 = this.scaleArray(bezierCurves[i][2]);
        let endPoint = this.scaleArray(bezierCurves[i][3]);
        path.bezierCurveTo(
          controlPoint1[0], 
          controlPoint1[1], 
          controlPoint2[0], 
          controlPoint2[1], 
          endPoint[0],
          endPoint[1],
        );
      }

      if (closePath) {
        path.closePath();
      }
      this.ctx.stroke(path);
    }
  }

  drawPoint(point, radius) {
    this.ctx.beginPath();
    this.ctx.arc(point.x * this.size.width, point.y * this.size.height, radius ?? DEFAULT_RADIUS, 0, 2 * Math.PI);
    this.ctx.fill();
  }
}
