export class StatsPanel {
  constructor() {
    this.startInferenceTime;
    this.numInferences = 0;
    this.inferenceTimeSum = 0;
    this.lastPanelUpdate = 0;

    const Stats = require('stats.js');
    this.stats = new Stats();
    this.stats.customFpsPanel = this.stats.addPanel(new Stats.Panel('FPS', '#0ff', '#002'));
    this.stats.showPanel(this.stats.domElement.children.length - 1);

    const parent = document.createElement("div");
    parent.id = "stats";
    parent.style.cssText = "position:relative;width:100%;height:80px";
    parent.appendChild(this.stats.domElement);
    document.body.appendChild(parent);

    const statsPanes = parent.querySelectorAll('canvas');

    for (let i = 0; i < statsPanes.length; ++i) {
      statsPanes[i].style.width = '140px';
      statsPanes[i].style.height = '80px';
    }
  }

  beginEstimateHandsStats() {
    this.startInferenceTime = (performance || Date).now();
  }
  
  endEstimateHandsStats() {
    const endInferenceTime = (performance || Date).now();
    this.inferenceTimeSum += endInferenceTime - this.startInferenceTime;
    ++this.numInferences;
  
    const panelUpdateMilliseconds = 1000;
    if (endInferenceTime - this.lastPanelUpdate >= panelUpdateMilliseconds) {
      const averageInferenceTime = this.inferenceTimeSum / this.numInferences;
      this.inferenceTimeSum = 0;
      this.numInferences = 0;
      this.stats.customFpsPanel.update(
        1000.0 / averageInferenceTime, 
        120 /* maxValue */
      );
      this.lastPanelUpdate = endInferenceTime;
    }
  }
}
