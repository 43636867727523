import fitCurve from 'fit-curve';

import {
  STATE, 
  VIDEO_SIZE, 
  MAX_RELATIVE_DISTANCE_FOR_KEYPOINT_MERGING, 
  BEZIER_FITTING_ERROR,
  NUM_RECOGNITIONS_UNTIL_STATE_CHANGES,
  MIN_RECOGNITION_SCORE
} from './params';

const fingerLookupIndices = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
}; // for rendering each finger as a polyline

const connections = [
  [0, 1], [1, 2], [2, 3], [3,4],
  [0, 5], [5, 6], [6, 7], [7, 8],
  [0, 9], [9, 10], [10, 11], [11, 12],
  [0, 13], [13,14], [14, 15], [15, 16],
  [0, 17], [17, 18],[18, 19], [19,20]
];

const GestureState = {
  DRAWING: 'drawing',
  MOVING: 'moving'
};

export class SheetState {
  constructor() {
    this.currentStroke = [];
    this.strokes = [];
    this.bezierStrokes = [];
    this.drawingFingerKeypoints = [];
    this.size = VIDEO_SIZE[STATE.camera.sizeOption];
    this.gestureState = GestureState.MOVING;
    this.drawingStateKeypointBuffer = [];
    this.nbRecognitionsOutOfDrawingPose = 0;
    this.lastClosenessReference = 0;
  }

  getClosenessReference(keypoints) {
    // NOTE: If a constant value is used, then the reference should be computed using the POSTPROCESSED keypoints!
    /*Math.max(
      this.getKeypointDistance(keypoints[7], keypoints[8]),
      this.getKeypointDistance(keypoints[5], keypoints[6]),
      this.getKeypointDistance(keypoints[3], keypoints[4])
    );*/
    let res = Math.max(
      this.getKeypointDistance(keypoints[3], keypoints[4]),
      this.getKeypointDistance(keypoints[5], keypoints[9])
    ) * 1.1;
    // Maybe filter the closeness reference, and not check it frame-wise?!
    this.lastClosenessReference = res;
    return res;
  }

  getKeypointDistance(firstKeypoint, secondKeypoint) {
    let distance = Math.pow(secondKeypoint.x - firstKeypoint.x, 2) 
    + Math.pow(secondKeypoint.y - firstKeypoint.y, 2);
    if(firstKeypoint.z != null && secondKeypoint.z != null){
      distance += Math.pow(secondKeypoint.z - firstKeypoint.z, 2);
    }
    distance = Math.sqrt(distance);
    return distance;
  }

  updateState(hands) {
    this.drawingFingerKeypoints = [];

    if(hands.length > 0){
      // TODO find a better way to choose the hand to look at (or use all hands in a clever way)
      // Sort by right to left hands.
      hands.sort((hand1, hand2) => {
        if (hand1.handedness < hand2.handedness) return 1;
        if (hand1.handedness > hand2.handedness) return -1;
        return 0;
      });

      if (
        hands[0].keypoints != null 
        && hands[0].keypoints3D != null
        && hands[0].score >= MIN_RECOGNITION_SCORE
      ) {
        this.updateStateForHand(hands[0].keypoints, hands[0].keypoints3D);
      }
    }
  }

  postProcess2DKeypoint(keypoint) {
    return {
      "x": (this.size.width - keypoint.x) / this.size.width,
      "y": keypoint.y / this.size.height,
    }
  }

  checkIfInDrawingPose(keypoints, keypoints3D) {
    let closenessReference = this.getClosenessReference(keypoints);

    let isInDrawingPose = false;
    /*for (let i = 0; i < fingerLookupIndices.indexFinger.length; i++) {
      const index = fingerLookupIndices.indexFinger[i];
      if(this.getKeypointDistance(keypoints[index], keypoints[4]) < closenessReference){
        this.drawingFingerKeypoints.push(
          this.postProcess2DKeypoint(keypoints[index])
        );
        isInDrawingPose = true;
      }
    }*/
    if(this.getKeypointDistance(keypoints[8], keypoints[4]) < closenessReference){
      this.drawingFingerKeypoints.push(
        this.postProcess2DKeypoint(keypoints[8])
      );
      isInDrawingPose = true;
    }
    return isInDrawingPose;
  }

  updateStateForHand(keypoints, keypoints3D) {
    let isInDrawingPose = this.checkIfInDrawingPose(keypoints, keypoints3D)

    // NOTE: This should *not* be placed inside the isInDrawingPose function!
    if(this.gestureState == GestureState.DRAWING){
      this.addKeypointToCurrentStroke(keypoints[8]);
    }

    if(isInDrawingPose){
      this.drawingFingerKeypoints.push(
        this.postProcess2DKeypoint(keypoints[4])
      );
      
      if(this.gestureState == GestureState.MOVING) {
        this.drawingStateKeypointBuffer.push(keypoints[4]); // NOTE: this one is intentionally not post-processed
        if(this.drawingStateKeypointBuffer.length >= NUM_RECOGNITIONS_UNTIL_STATE_CHANGES){
          for(let i = 0; i < this.drawingStateKeypointBuffer.length; i++){
            this.addKeypointToCurrentStroke(this.drawingStateKeypointBuffer[i]);
          }
          this.drawingStateKeypointBuffer = [];
          this.gestureState = GestureState.DRAWING;
        }
      }

      this.nbRecognitionsOutOfDrawingPose = 0;
    }else{
      this.stateChangeKeypointBuffer = [];
      this.nbRecognitionsOutOfDrawingPose += 1;
      if(
        this.gestureState == GestureState.DRAWING
        && this.nbRecognitionsOutOfDrawingPose >= NUM_RECOGNITIONS_UNTIL_STATE_CHANGES
      ){
        this.gestureState = GestureState.MOVING;
        if(this.currentStroke.length >= NUM_RECOGNITIONS_UNTIL_STATE_CHANGES){
          for(let i = 0; i < NUM_RECOGNITIONS_UNTIL_STATE_CHANGES - 1; i++) {
            this.currentStroke.pop();
          }
        }
        if(this.currentStroke.length > 0){
          this.strokes.push(this.currentStroke);
          let bezierCurves = this.fitBezierCurves(this.currentStroke);
          this.bezierStrokes.push(bezierCurves);
        }
        this.currentStroke = [];
      }
    }
  }

  addKeypointToCurrentStroke(keypoint) {
    let postprocessedKeypoint = this.postProcess2DKeypoint(keypoint);
    if(
      this.currentStroke.length > 0
      && this.getKeypointDistance(this.currentStroke[this.currentStroke.length - 1], postprocessedKeypoint) < MAX_RELATIVE_DISTANCE_FOR_KEYPOINT_MERGING
    ){
      let lastKeypoint = this.currentStroke.pop();
      let mergedKeypoint = {
        "x": (postprocessedKeypoint.x + lastKeypoint.x) / 2.0,
        "y": (postprocessedKeypoint.y + lastKeypoint.y) / 2.0
      }
      this.currentStroke.push(mergedKeypoint);
    }else{
      this.currentStroke.push(postprocessedKeypoint);
    }
  }

  getState() {
    return {
      strokes: this.strokes.concat([this.currentStroke])
    }
  }

  fitBezierCurves(stroke) {
    let points = [];
    for(let i = 0; i < stroke.length; i++){
      points.push([stroke[i].x, stroke[i].y]);
    }
    // TODO: Does it make sense to *not* resample, but instead stay in the bezier world? There are ready-to-use drawing tools for that
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/bezierCurveTo
    // However, it should not be too hard to interpolate the bezier curves, see
    // http://devmag.org.za/2011/04/05/bzier-curves-a-tutorial/
    let bezierCurves = fitCurve(points, BEZIER_FITTING_ERROR);
    return bezierCurves;
  }
}
