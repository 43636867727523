import '@tensorflow/tfjs-backend-webgl';
import * as mpHands from '@mediapipe/hands';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import * as handdetection from '@tensorflow-models/hand-pose-detection';

import {Camera} from './camera';
import {STATE} from './params';
import {StatsPanel} from './stats_panel';
import {setBackendAndEnvFlags} from './util';
import {SheetState} from './sheet_state';
import {DrawingBoard} from './drawing_board';

let detector, camera, statsPanel, drawingBoard;
let sheetState = new SheetState();
let loopDetections = false;
let initialized = false;
let renderResults = false;

async function createDetector() {
  switch (STATE.model) {
    case handdetection.SupportedModels.MediaPipeHands:
      const runtime = STATE.backend.split('-')[0];
      if (runtime === 'mediapipe') {
        return handdetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          maxHands: STATE.modelConfig.maxNumHands,
          solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${mpHands.VERSION}`
        });
      } else if (runtime === 'tfjs') {
        return handdetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          maxHands: STATE.modelConfig.maxNumHands
        });
      }
  }
}

async function performPrediction() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(video);
      };
    });
  }

  let hands = null;

  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    statsPanel?.beginEstimateHandsStats();

    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      hands = await detector.estimateHands(
          camera.video,
          {flipHorizontal: false});
    } catch (error) {
      detector.dispose();
      detector = null;
      alert(error);
    }

    statsPanel?.endEstimateHandsStats();
  }

  return hands;
}

async function predictionLoop() {
  let hands = await performPrediction();
  sheetState.updateState(hands);
  
  if(renderResults){
    camera.drawCtx();
    drawingBoard.draw(sheetState);
  }
  if(loopDetections){
    requestAnimationFrame(predictionLoop);
  }
};

export async function addStatsPanel() {
  statsPanel = new StatsPanel();
}
window.addStatsPanel = addStatsPanel;

export async function startPredictionLoop() {
  if(!initialized){
    camera = await Camera.setupCamera();
    drawingBoard = new DrawingBoard();
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    detector = await createDetector();
    initialized = true;
  }

  loopDetections = true;
  predictionLoop();
};
window.startPredictionLoop = startPredictionLoop;

export async function stopPredictionLoop() {
  loopDetections = false;
}
window.stopPredictionLoop = stopPredictionLoop;

export function toggleRenderResults() {
  renderResults = !renderResults;
}
window.toggleRenderResults = toggleRenderResults;

export function getSheetState() {
  return sheetState.getState();
}
window.getSheetState = getSheetState;

export function clearSheet() {
  sheetState = new SheetState();
}
window.clearSheet = clearSheet;

export function testFun() {
  return true;
}
window.testFun = testFun;